import React from "react";
import { Link } from "react-router-dom";

const HeaderMainPage = ({ title, url }) => {
  return (
    <div className="bgBar">
      <Link to={url} className="backlink iconBackAb">
        <img
          src={require("../../images/icon-back.svg").default}
          alt="icon-back"
        />
      </Link>
      {title}
    </div>
  );
};

export default HeaderMainPage;
