import React from "react";
import HeaderMainPage from "../../../components/header/mainPage";
import httpClient from "../../../services/httpClient";
import toast from "react-hot-toast";
import { copyToClipboard } from "../../../services/helpers";
import ContactComponent from "../../../components/ContactComponent";
import AlertNotify from "../../../components/AlertNotify";

const TrueMoney = () => {
  const [banks, setBanks] = React.useState([]);
  const getBanks = async () => {
    try {
      const resp = await httpClient.get("/main/getAccountForDeposit");
      const data = resp.data.data.filter((item) => item.bank_list_id === 24);
      setBanks(data);
    } catch (error) {}
  };

  const formatNumber = (num) => {
    const regex = /^(\d{3})(\d{1})(\d{5})(\d{1})$/;
    return num.replace(regex, "$1-$2-$3-$4");
  };

  const handleCopy = (text) => {
    copyToClipboard(text);
    toast.success(`คัดลอก ${text} สำเร็จ`);
  };

  React.useEffect(() => {
    getBanks();
  }, []);
  return (
    <div className="container">
      <AlertNotify />
      <div className="row justify-content-center">
        <div className="col-md-6">
          <HeaderMainPage title="ทรูวอลเล็ต" url="/auth/dashboard" />
          <div className="boxTopRounded miniToplogo">
            <img
              src={require("../../../images/logo.svg").default}
              alt="logo"
              className="logoTopAb"
            />
            <div className="text-center">
              * ต้องใช้เบอร์ทรูวอลเล็ตที่สมัคร โอนมาเท่านั้น <br />
              * หากไม่ใช่เบอร์ที่สมัคร ระบบจะไม่ทำรายการ <br />
              * ห้ามใช้ บัญชีธนาคารโอนเข้าทรูวอลเล็ต <br />
              * กรณีที่โอนโดยไม่ใช้เบอร์ที่สมัคร ทางเว็บขอไม่ทำรายการ
              และมีค่าธรรมเนียมโอนคืน 20 บาททุกกรณี <br />*
              กรณีโอนมาเพื่อใช้แลกเงินสด โอนแล้วถอนเลย ไม่มีประวัติการเล่นเกม
              จะไม่ทำรายการถอนหรือถ้าโอนกลับจะมีค่าธรรมเนียม 20 บาททุกกรณี{" "}
              <br />
              * กรณีโอนผ่านเค้าเตอร์ร้านเซเว่น ให้ติดต่อแอดมิน <br />
              <p>
                <ContactComponent />
              </p>
            </div>
          </div>
          <h3 className="text-center">ฝากด้วยทรูวอลเล็ตจะไม่ได้รับโปรโมชั่น</h3>
          {banks.map((bank, index) => (
            <div className="boxTopRounded topBMargin boxSmRounded" key={index}>
              <div className="rowBank">
                <div className="iconBank">
                  <i
                    className={`bank bank-${bank.bank_list.bank_abbrev_en.toLowerCase()} huge`}
                  ></i>
                </div>
                <div className="colBankname ms-3">
                  {bank.bank_list.bank_name_th}
                </div>
              </div>
              <div className="rowName">
                <h4>{bank.name}</h4>
                <span>เลขที่บัญชี {formatNumber(bank.account_number)}</span>
              </div>
              <div className="rowVerify">
                <span
                  style={{ cursor: "pointer", color: "#ffda22" }}
                  onClick={() => handleCopy(bank.account_number)}
                  className="miniBtn"
                >
                  คัดลอกเลขบัญชี
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TrueMoney;
