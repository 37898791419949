import React from "react";
import ImageBack from "../images/icon-back.svg";
import ImageLogo from "../images/logo.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import httpClient from "../services/httpClient";
import { toast } from "react-hot-toast";
import { Button } from "react-bootstrap";

const OtpPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [otpRef, setOtpRef] = React.useState("");
  const [otp, setOtp] = React.useState("");

  const handleChange = (e) => {
    setOtp(e.target.value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const resp1 = await httpClient.post("/authen/verifyOTP", {
        ref: otpRef,
        otp: otp,
      });
      if (resp1.data.data.isExpire) {
        navigate("/");
      }
      if (resp1.data.data.status) {
        localStorage.setItem("phoneNumber", phoneNumber);
        localStorage.setItem("ref", otpRef);
        if (localStorage.getItem("register")) {
          localStorage.removeItem("register");
        }

        navigate("/register", {
          state: {
            phoneNumber: phoneNumber,
            ref: otpRef,
          },
        });
      }
    } catch (error) {
      toast.error("รหัส OTP ไม่ถูกต้อง");
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (!location.state || !location.state.phoneNumber || !location.state.ref) {
      navigate("/");
    } else {
      setPhoneNumber(location.state.phoneNumber);
      setOtpRef(location.state.ref);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <Link to="/" className="backlink">
            <img src={ImageBack} alt="ImageBack" />
          </Link>
          <div className="boxTopRounded topBMargin">
            <img src={ImageLogo} alt="logoTopAb" className="logoTopAb" />
            <h2 className="titleH2">ใส่รหัส OTP</h2>
            <p className="text-center">
              ใส่รหัส OTP จากข้อความทาง SMS มือถือคุณ
              <br />
              เบอร์โทร: <span className="colorlink">{phoneNumber}</span>
              <br />
              เลขอ้างอิง Ref: <span className="colorlink">{otpRef}</span>
            </p>
            <div className="formBox">
              <input
                disabled={loading}
                onChange={handleChange}
                type="text"
                className="form-control inputBox text-center"
                placeholder="ระบุรหัส OTP"
              />
            </div>
            <div className="d-grid gap-2">
              {loading ? (
                <Button disabled variant="primary" className="btnPrimary">
                  กำลังโหลด...
                </Button>
              ) : (
                <Button onClick={handleSubmit} className="btnPrimary">
                  ยืนยัน OTP
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpPage;
