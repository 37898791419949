import React from "react";
import ImageInfo from "../../images/icon-info.svg";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";

const FormRegisterController = ({ knowus, bankLists, onDataFromChild }) => {
  const { register, handleSubmit, setValue } = useForm();
  const onSubmit = async (data) => {
    onDataFromChild(data);
  };
  // const registerData = useSelector((state: any) => state.authReducer.registerData);

  React.useEffect(() => {
    let registerData = localStorage.getItem("register");
    registerData = JSON.parse(registerData);
    if (registerData) {
      setValue("phoneNumber", registerData.phoneNumber);
      setValue("f_name", registerData?.f_name);
      setValue("l_name", registerData?.l_name);
      setValue("line_id", registerData?.line_id);
      setValue("gender_id", registerData.gender_id);
      setValue("knowus_id", registerData.knowus_id);
      setValue("bank_list_id", registerData.bank_list_id);
      setValue("number", registerData.number);
    } else {
      setValue("phoneNumber", localStorage.getItem("phoneNumber"));
      setValue("bank_list_id", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <div className="formBox">
        <input
          {...register("f_name")}
          type="text"
          id="f_name"
          className="form-control inputBox"
          placeholder="ชื่อ"
        />
      </div>
      <div className="formBox">
        <input
          {...register("l_name")}
          type="text"
          id="l_name"
          className="form-control inputBox"
          placeholder="นามสกุล"
        />
        <div className="formbox">
          <input
            type="text"
            {...register("line_id")}
            id="line_id"
            className="form-control inputBox"
            placeholder="ID LINE (หากไม่มีใส่เบอร์มือถือแทน)"
          />
        </div>
        <div className="formbox">
          <input
            type="text"
            disabled
            {...register("phoneNumber")}
            id="phoneNumber"
            className="form-control inputBox"
            placeholder="ID LINE (หากไม่มีใส่เบอร์มือถือแทน)"
          />
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            {...register("gender_id")}
            id="man_check"
            defaultValue="1"
            defaultChecked
          />
          <label className="form-check-label" htmlFor="radiogen1">
            ชาย
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            {...register("gender_id")}
            id="women_check"
            defaultValue="2"
          />
          <label className="form-check-label" htmlFor="radiogen2">
            หญิง
          </label>
        </div>
      </div>
      <br />
      <div className="boxTopRounded topBMargin boxSmRounded">
        <h3 className="titleWithLine">คุณรู้จักเราจักที่ไหน</h3>
        <div className="row">
          {knowus.map((item, index) => {
            return (
              <div className="col-md-6 col-6" key={index}>
                <div className="input_box">
                  <input
                    className="form-check-input"
                    {...register("knowus_id")}
                    type="radio"
                    defaultValue={item.id}
                    id={`radio-${index}`}
                  />
                  <label
                    className="form-check-label ms-2 mt-1"
                    htmlFor={`radio-${index}`}
                  >
                    {item.name}
                  </label>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="boxTopRounded topBMargin boxSmRounded">
        <h3 className="titleWithLine">ข้อมูลบัญชีธนาคาร</h3>
        <div className="bgInfo">
          <img src={ImageInfo} alt="ImageInfo" />
          ข้อมูลบัญชีธนาคารต้องตรงกับชื่อที่สมัครเท่านั้น
          และเลขบัญชีต้องตรงกับบัญชีจริงเท่านั้นจึงจะทำรายการ ฝาก-ถอนได้
          ถ้าหากแจ้งบัญชีชื่อมาไม่ตรงกับชื่อ-นามสกุล ที่ลงทะเบียน
          ขอสงวนสิทธิ์ในการถอนทุกกรณี
        </div>
        <select
          className="select7 form-control inputBox"
          {...register("bank_list_id")}
          required
        >
          <option value="">กรุณาเลือกธนาคาร...</option>
          {bankLists.map((item, index) => {
            return (
              <option key={index} value={item.id}>
                {item.bank_name_th}
              </option>
            );
          })}
        </select>
        <div className="formBox">
          <input
            type="text"
            className="form-control inputBox"
            placeholder="เลขที่บัญชี"
            {...register("number")}
          />
        </div>
        <div className="d-grid gap-2">
          <Button type="submit" className="btnPrimary">
            สมัครสมาชิก
          </Button>
        </div>
      </div>
    </form>
  );
};

export default FormRegisterController;
