import React from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";

import httpClient from "../services/httpClient";
import { Button } from "react-bootstrap";

const IndexPage = () => {
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();

  React.useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      navigate("/auth/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      if (/^0[1-9]{1}[0-9]{1}[0-9]{3}[0-9]{4}$/.test(data.phoneNumber)) {
        const res = await httpClient.post("/authen/checkPhoneNumber", {
          phoneNumber: data.phoneNumber,
        });
        if (res.data.data.isCustomer) {
          navigate("/pin/", { state: { phoneNumber: data.phoneNumber } });
        } else {
          const resp = await httpClient.post("/authen/generateOTP", {
            phoneNumber: data.phoneNumber,
          });
          localStorage.setItem("phoneNumber", data.phoneNumber);
          localStorage.setItem("ref", resp.data.data);
          if (localStorage.getItem("register")) {
            localStorage.removeItem("register");
          }
          navigate("/register", {
            state: {
              phoneNumber: data.phoneNumber,
              ref: resp.data.data,
            },
          });
        }
      } else {
        toast.error("เบอร์โทรศัพท์ไม่ถูกต้อง");
      }
    } catch (error) {
      toast.error("เบอร์โทรศัพท์ไม่ถูกต้อง");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="boxTopRounded topBMargin">
            <img
              src={require("../images/logo.svg").default}
              alt="logo"
              className="logoTopAb"
            />
            <h2 className="titleH2">สมัครสมาชิก/เข้าสู่ระบบ</h2>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="formBox">
                <input
                  type="text"
                  className="form-control inputBox inputLeft"
                  placeholder="กรอกเบอร์มือถือ"
                  {...register("phoneNumber")}
                  disabled={loading}
                />
                <img
                  src={require("../images/icon-mobile.svg").default}
                  alt="mobole_icons"
                  className="iconLeft"
                />
              </div>
              {loading ? (
                <Button disabled className="btnPrimary btnFullWidth">
                  กำลังโหลด...
                </Button>
              ) : (
                <Button className="btnPrimary btnFullWidth" type="submit">
                  ขั้นตอนต่อไป
                </Button>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndexPage;
