import React from "react";
import { toast } from "react-hot-toast";
import httpClient from "../../services/httpClient";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
const genders = [
  { id: 1, name: "ชาย" },
  { id: 2, name: "หญิง" },
];
const FormBeforeSubmit = ({ data, knowus, bankLists, onPageConfirm }) => {
  const navigate = useNavigate();
  const [knowusName, setKnowusName] = React.useState("");
  const [bankName, setBankName] = React.useState("");
  const [genderName, setGenderName] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const foundData = knowus.find((dt) => dt.id === Number(data.knowus_id));
    const foundBank = bankLists.find(
      (dt) => dt.id === Number(data.bank_list_id)
    );
    const foundGender = genders.find((dt) => dt.id === Number(data.gender_id));
    setBankName(
      foundBank?.bank_abbrev_en +
        " : " +
        foundBank.bank_abbrev_th +
        " : " +
        data.number
    );
    setKnowusName(foundData?.name);
    setGenderName(foundGender?.name);
  }, [knowus, bankLists, data]);

  const setPageConfirm = () => {
    onPageConfirm(false);
  };

  const sendBeforePin = async () => {
    setLoading(true);
    try {
      const regisData = JSON.parse(localStorage.getItem("register"));
      const res1 = await httpClient.post("/authen/beforePin", regisData);
      const registerId = res1.data.data;
      localStorage.setItem("registerId", registerId);
      localStorage.removeItem("phoneNumber");
      localStorage.removeItem("register");
      localStorage.removeItem("ref");
      navigate("/create-pin");
    } catch (error) {
      if (error.response.data.errors) {
        Object.values(error.response.data.errors).map((error, index) =>
          toast.error(error[0])
        );
      } else {
        toast.error(error.response.data.message);
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <div className="row posRes">
        <div className="col-3">
          <label className="label_dark">ชื่อ-สกุล</label>
        </div>
        <div className="col-9">
          <div className="posRes">
            <input
              className="form-control inputBox"
              value={data.f_name + " " + data.l_name}
              readOnly
            />
          </div>
        </div>
      </div>
      <div className="row posRes">
        <div className="col-3">
          <label className="label_dark">ไอดีไลน์</label>
        </div>
        <div className="col-9">
          <div className="posRes">
            <input
              className="form-control inputBox"
              value={data.line_id}
              readOnly
            />
          </div>
        </div>
      </div>
      <div className="row posRes">
        <div className="col-3">
          <label className="label_dark">เบอร์โทร</label>
        </div>
        <div className="col-9">
          <div className="posRes">
            <input
              className="form-control inputBox"
              value={data.phoneNumber}
              readOnly
            />
          </div>
        </div>
      </div>
      <div className="row posRes">
        <div className="col-3">
          <label className="label_dark">เพศ</label>
        </div>
        <div className="col-9">
          <div className="posRes">
            <input
              className="form-control inputBox"
              value={genderName}
              readOnly
            />
          </div>
        </div>
      </div>
      <div className="row posRes">
        <div className="col-3">
          <label className="label_dark">รู้จักจาก</label>
        </div>
        <div className="col-9">
          <div className="posRes">
            <input
              className="form-control inputBox"
              value={knowusName}
              readOnly
            />
          </div>
        </div>
      </div>
      <div className="row posRes">
        <div className="col-3">
          <label className="label_dark">ธนาคาร</label>
        </div>
        <div className="col-9">
          <div className="posRes">
            <input
              className="form-control inputBox"
              value={bankName}
              readOnly
            />
          </div>
        </div>
      </div>
      <div className="row posRes">
        <div
          className="col-12 text-center"
          style={{
            color: "red",
            backgroundColor: "rgb(255 255 255)",
            padding: "12px",
            borderRadius: "15px",
          }}
        >
          <strong>
            *** ชื่อ-สกุล เดียวกัน สามารถสมัครได้เพียงครั้งเดียวเท่านั้น
            <br /> หากตรวจสอบพบว่ามีชื่อซ้ำ ขอสงวนสิทธิ์ในการถอนทุกกรณี
          </strong>
        </div>
        <div className="d-grid gap-2 mt-2">
          {loading ? (
            <Button disabled variant="warning">
              กำลังโหลด...
            </Button>
          ) : (
            <>
              <Button onClick={sendBeforePin} variant="warning">
                ยืนยัน
              </Button>
              <Button onClick={setPageConfirm} variant="danger">
                กลับ
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default FormBeforeSubmit;
