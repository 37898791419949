import React from "react";
import ImageLogo from "../images/logo.svg";
import FormRegisterController from "../components/register/FormRegisterController";
import httpClient from "../services/httpClient";
import FormBeforeSubmit from "../components/register/FormBeforeSubmit";
import { useDispatch } from "react-redux";
import { updateRegisterData } from "../redux/actions/authAction";
import { useNavigate } from "react-router-dom";

const RegisterPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [knowus, setKnowus] = React.useState([]);
  const [bankLists, setBankLists] = React.useState([]);
  const [regData, setRegData] = React.useState({});
  const [pageConfirm, setPageConfirm] = React.useState(false);

  const getData = async () => {
    try {
      const resp1 = await httpClient.get("/authen/getDetailForRegister");
      setKnowus(resp1.data.data.knowus);
      setBankLists(resp1.data.data.bankLists);
    } catch (error) {}
  };

  const updateData = () => {
    const RegisterValue = localStorage.getItem("register");
    if (RegisterValue) {
      dispatch(updateRegisterData(JSON.parse(RegisterValue)));
    }
  };

  React.useEffect(() => {
    if (!localStorage.getItem("phoneNumber")) {
      navigate("/");
    } else {
      const registerdt = JSON.parse(localStorage.getItem("register"));
      if (registerdt) {
        setRegData(registerdt);
      }
      getData();
      updateData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDataFromChild = (data) => {
    data.phoneNumber = localStorage.getItem("phoneNumber");
    data.ref = localStorage.getItem("ref");
    localStorage.setItem("register", JSON.stringify(data));
    setRegData(data);
    setPageConfirm(true);
  };

  const setPageConfirmFalse = () => {
    setPageConfirm(false);
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-6 col-md-12 col-sm-12">
          <h2 className="titleOutBox">ข้อมูลส่วนตัว</h2>
          <div className="boxTopRounded topBMargin boxSmRounded">
            <img
              src={ImageLogo}
              alt="logoTopAb"
              className="logoTopAb imgRight"
            />
            <h3 className="titleWithLine">ข้อมูลทั่วไป</h3>
            {pageConfirm ? (
              <FormBeforeSubmit
                data={JSON.parse(localStorage.getItem("register"))}
                knowus={knowus}
                bankLists={bankLists}
                onPageConfirm={setPageConfirmFalse}
              />
            ) : (
              <FormRegisterController
                knowus={knowus}
                bankLists={bankLists}
                onDataFromChild={handleDataFromChild}
                registerData={regData}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
