import React from "react";

import { Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import IndexPage from "./pages/IndexPage";
import PageNotFound from "./pages/PageNotFound";
import PinPage from "./pages/PinPage";
import ProtectedRoutes from "./middlewares/protectedRoute";
import AuthDashboardPage from "./pages/auth/DashboardPage";
import Choose from "./pages/auth/deposit/Choose";
import Banks from "./pages/auth/deposit/Banks";
import TrueMoney from "./pages/auth/deposit/TrueMoney";
import WithdrawIndex from "./pages/auth/withdraw/Index";
import Profile from "./pages/auth/Profile";
import Bonus from "./pages/auth/Bonus";
import AuthCondition from "./pages/auth/Conditions";
import History from "./pages/auth/History";
import OtpPage from "./pages/OtpPage";
import RegisterPage from "./pages/RegisterPage";
import CreatePinPage from "./pages/CreatePinPage";
import ConfirmPinPage from "./pages/ConfirmPinPage";
import ConfirmPromotion from "./pages/ConfirmPromotion";

function App() {
  React.useEffect(() => {
    document.body.classList.add("bgMain");
  }, []);
  return (
    <>
      <Routes>
        <Route path="/" element={<IndexPage />} />
        <Route path="/pin" element={<PinPage />} />
        <Route path="/otp" element={<OtpPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/create-pin" element={<CreatePinPage />} />
        <Route path="/confirm-pin" element={<ConfirmPinPage />} />
        <Route path="/confirm-promotion" element={<ConfirmPromotion />} />
        <Route element={<ProtectedRoutes />}>
          <Route path="/auth/dashboard" element={<AuthDashboardPage />} />
          <Route path="/auth/choose" element={<Choose />} />
          <Route path="/auth/deposit/banks" element={<Banks />} />
          <Route path="/auth/deposit/truemoney" element={<TrueMoney />} />
          <Route path="/auth/withdraw" element={<WithdrawIndex />} />
          <Route path="/auth/profile" element={<Profile />} />
          <Route path="/auth/bonus" element={<Bonus />} />
          <Route path="/auth/conditions" element={<AuthCondition />} />
          <Route path="/auth/histories" element={<History />} />
        </Route>

        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
