import React from "react";
import HeaderMainPage from "../../components/header/mainPage";
import { Table, Badge } from "react-bootstrap";

import httpClient from "../../services/httpClient";
import AlertNotify from "../../components/AlertNotify";

const statusType = (type) => {
  switch (type) {
    case 1:
      return <Badge bg="success">สำเร็จ</Badge>;
    case 2:
      return <Badge bg="primary">กำลังทำรายการ</Badge>;
    case 3:
      return <Badge bg="danger">ยกเลิก</Badge>;
    case 4:
      return (
        <span className="badge" style={{ backgroundColor: "rgb(211 133 22)" }}>
          ติดต่อแอดมิน
        </span>
      );
    case 5:
      return <Badge bg="primary">กำลังทำรายการ</Badge>;
    case 6:
      return <Badge bg="info">ฝากค้าง</Badge>;
    case 7:
      return (
        <span className="badge" style={{ backgroundColor: "rgb(211 133 22)" }}>
          ตัดเครดิต
        </span>
      );
    case 8:
      return <Badge bg="danger">เล่นเกมผิดเงื่อนไข</Badge>;
    case 9:
      return <Badge bg="danger">ตัดเครดิตตามเงื่อนไข</Badge>;
    default:
      return <Badge bg="success">สำเร็จ</Badge>;
  }
};

const History = () => {
  const [reports, setReports] = React.useState([]);
  const getData = async () => {
    try {
      const resp = await httpClient.get("/main/getReport");
      setReports(resp.data.data.data);
    } catch (error) {}
  };

  React.useEffect(() => {
    getData();
  }, []);
  return (
    <div className="container">
      <AlertNotify />
      <div className="row justify-content-center">
        <div className="col-md-6">
          <HeaderMainPage title="ประวัติฝากถอน" url="/auth/dashboard" />
          <div className="boxTopRounded miniToplogo">
            <img
              src={require("../../images/logo.svg").default}
              alt="logotop"
              className="logoTopAb"
            />

            <Table
              bordered
              style={{
                color: "white",
              }}
            >
              <thead>
                <tr
                  style={{
                    backgroundColor: "#5636b3",
                    color: "white",
                  }}
                >
                  <th>วันที่/เวลา</th>
                  <th>จำนวนเงิน</th>
                  <th>โบนัส</th>
                  <th>สถานะ</th>
                </tr>
              </thead>
              <tbody
                style={{
                  color: "whitesmoke",
                }}
              >
                {reports.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.date}</td>
                      <td>
                        {item.type_id === 1 ||
                        item.type_id === 4 ||
                        item.type_id === 6 ||
                        item.type_id === 7 ? (
                          <span className="text-success">{item.amount}</span>
                        ) : (
                          <span className="text-danger">-{item.amount}</span>
                        )}
                      </td>
                      <td>
                        <span className="text-info">{item.bonus}</span>
                      </td>
                      <td>{statusType(item.status_id)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default History;
