import React from "react";
import logo from "../images/logo.svg";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="boxTopRounded topBMargin">
            <img src={logo} alt="logo" className="logoTopAb" />
            <h2 className="titleH2">404 Not Found</h2>
            <p className="text-center">
              ขออภัย ไม่พบหน้าที่คุณกำลังมองหา อาจถูกย้าย ลบ
              หรือใช้งานไม่ได้ชั่วคราว โปรดตรวจสอบ URL หรือลองอีกครั้งในภายหลัง
            </p>
            <Link to="/" className="btnPrimary">
              กลับหน้าหลัก
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
