import React from "react";
import HeaderMainPage from "../../components/header/mainPage";
import httpClient from "../../services/httpClient";
import ImageCondition from "../../images/rule.jpg";
import AlertNotify from "../../components/AlertNotify";

const Conditions = () => {
  const [promotions, setPromotions] = React.useState([]);

  const getData = async () => {
    try {
      const res1 = await httpClient.get("/main/getPromotions");
      setPromotions(res1.data.data);
    } catch (error) {
    } finally {
    }
  };
  React.useEffect(() => {
    getData();
  }, []);
  return (
    <div className="container">
      <AlertNotify />
      <div className="row justify-content-center">
        <div className="col-md-6">
          <HeaderMainPage title="โปรโมชั่นทั้งหมด" url="/auth/dashboard" />
          <div className="boxTopRounded topBMargin boxSmRounded mb20">
            <span className="imgBonus">
              <img src={ImageCondition} alt="banner" />
            </span>
          </div>
          {promotions
            .slice()
            .reverse()
            .map((item, index) => {
              return (
                <div
                  className="boxTopRounded topBMargin boxSmRounded mb20"
                  key={index}
                >
                  <span className="imgBonus">
                    <img src={item.image} alt="banner" />
                  </span>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Conditions;
