import React from "react";
import HeaderMainPage from "../../components/header/mainPage";
import toast from "react-hot-toast";
import httpClient from "../../services/httpClient";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AlertNotify from "../../components/AlertNotify";

const MySwal = withReactContent(Swal);

const backgroundRounded = {
  backgroundImage:
    "linear-gradient(to right, #051937, #004d7a, #008793, #00bf72, #a8eb12)",
  borderRadius: "10px 10px 10px 10px",
};

const Bonus = () => {
  const [promotions, setPromotions] = React.useState([]);

  const getData = async () => {
    try {
      const res1 = await httpClient.get("/main/getPromotions");
      setPromotions([]);
      const filteredPromotion = res1.data.data.filter(
        (item) => item.name !== "ไม่รับโบนัส"
      );

      setPromotions(filteredPromotion);
    } catch (error) {
    } finally {
    }
  };
  React.useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const storePromotion = async (index) => {
    try {
      const promotion = {
        promotionId: promotions[index].bonus ? 1 : promotions[index].id,
      };
      let txt;
      if (promotion.promotionId === 1) {
        txt =
          '<p style="font-size: 18px">ตั้งค่าเป็น <strong style="color: blue">ไม่รับ</strong> โปรโมชั่นเรียบร้อย</p>';
      } else {
        txt =
          '<p style="font-size: 18px">ตั้งค่าเป็น <strong style="color: blue">รับ</strong> โปรโมชั่นเรียบร้อย</p>';
      }
      await httpClient.post("/main/storePromotions", promotion);
      MySwal.fire({
        title: "เสร็จสิ้น!",
        html: txt,
        icon: "success",
      });
      await getData();
    } catch (error) {
      toast.error("มีข้อผิดพลาด ไม่สามารถเปลี่ยนโปรโมชั่นได้");
    }
  };
  return (
    <div className="container">
      <AlertNotify />
      <div className="row justify-content-center">
        <div className="col-md-6">
          <HeaderMainPage title="ตั้งค่ารับโบนัส" url="/auth/dashboard" />

          {promotions.map((item, index) => (
            <React.Fragment key={index}>
              <div className="boxTopRounded topBMargin boxSmRounded mb20">
                <a href={() => false} className="imgBonus">
                  <img src={item.image} alt="banner" />
                </a>
                <div style={backgroundRounded} className="p-2 round">
                  <div className="form-check form-switch ">
                    <input
                      onClick={() => storePromotion(index)}
                      className={`form-check-input checkbox-changer ${
                        item.bonus ? "active" : ""
                      }`}
                      type="checkbox"
                      id="switch"
                      checked={item.bonus}
                    />
                    <label className="form-check-label" htmlFor="switch">
                      ต้องการรับโบนัสหรือไม่?
                    </label>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Bonus;
