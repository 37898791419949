import React from "react";
import HeaderMainPage from "../../../components/header/mainPage";
import { toast } from "react-hot-toast";
import httpClient from "../../../services/httpClient";
import { useNavigate } from "react-router-dom";
import ContactComponent from "../../../components/ContactComponent";
import AlertNotify from "../../../components/AlertNotify";

const WithdrawIndex = () => {
  const navigate = useNavigate();
  const [realWithdraw, setRealWithdraw] = React.useState(0);
  const [withdrawLimit, setWithdrawLimit] = React.useState(0);
  const [turnover, setTurnover] = React.useState(0);
  const [withdrawMNM, setWithdrawMNM] = React.useState(0);
  const [balance, setBalance] = React.useState(0);
  const [outstanding, setOutstanding] = React.useState(0);
  const [amount, setAmount] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const getData = async () => {
    setLoading(true);
    try {
      const resp2 = await httpClient.get("/main/getDetailForWithdraw");
      const resp3 = await httpClient.get("/game/getCredit");

      setRealWithdraw(resp2.data.realWithdraw);
      setWithdrawLimit(resp2.data.withdrawLimit);
      if (resp2.data.turnover.status) {
        setTurnover(resp2.data.turnover.turnover);
      }
      setWithdrawMNM(resp2.data.withdrawMNM);
      setBalance(resp3.data.data.balance);
      setOutstanding(resp3.data.data.outstanding);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getData();
  }, []);

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const acceptNumber = (number) => {
    if (number <= 0) return false;
    const x = number.match(/\./g);
    if (x) {
      return false;
    } else {
      return true;
    }
  };

  const handleWithdraw = async () => {
    setLoading(true);
    try {
      if (!acceptNumber(amount)) {
        toast.error("กรุณากรอกจำนวนเต็มเท่านั้น");
        return;
      }

      if (amount < withdrawMNM) {
        toast.error(`จำนวนเงินต้องไม่น้อยกว่า ${withdrawMNM}`);
        return;
      }

      if (amount > realWithdraw) {
        toast.error(`จำนวนเงินต้องไม่เกิน ${realWithdraw}`);
        return;
      }

      if (amount > balance) {
        toast.error(`ยอดเงินไม่พอสำหรับถอน`);
        return;
      }

      await httpClient.post("/main/withdraw", {
        amount,
      });
      navigate("/auth/histories");
      toast.success("ทำรายการสำเร็จ");
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="container">
      <AlertNotify />
      <div className="row justify-content-center">
        <div className="col-md-6">
          <HeaderMainPage title="แจ้งถอนเงิน" url="/auth/dashboard" />
          <div className="boxTopRounded miniToplogo">
            <img
              src={require("../../../images/logo.svg").default}
              alt="logo"
              className="logoTopAb"
            />
            <div className="text-center">
              ระบุจำนวนเงินที่ต้องการถอน หากพบปัญหาการถอนเงิน หากยอดเงินไม่เข้า
              <p>
                <ContactComponent />
              </p>
            </div>
          </div>
          <h3 className="text-center">จำนวนเงินที่ต้องการถอน</h3>
          <div className="boxTopRounded topBMargin boxSmRounded">
            <div className="formBox">
              <input
                type="text"
                className="form-control inputBox text-center"
                defaultValue={0}
                onChange={handleAmountChange}
                disabled={loading}
              />
            </div>
            {!loading ? (
              <button
                onClick={handleWithdraw}
                className="btnPrimary btnFullWidth"
              >
                ยืนยันการถอน
              </button>
            ) : (
              <button disabled className="btnPrimary btnFullWidth">
                Loading...
              </button>
            )}

            <small className="smRemark" style={{ fontSize: "20px" }}>
              ยอดเงินที่สามารถถอนได้: <span>{balance}</span>
              <br />
              ยอดเงินที่สามารถถอนได้ต่อวัน :{" "}
              <span>
                {realWithdraw} / {withdrawLimit}
              </span>
              <br />
              ยอดเทิร์นโอเวอร์:{" "}
              <span>
                {balance} / {turnover}
              </span>
              <br />
              ยอดค้างในเกมส์: <span>{outstanding}</span>
            </small>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithdrawIndex;
