import React from "react";

const BoxZoom = ({ image }) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const scale = isHovered ? 1.1 : 1;

  const mystyle = {
    minWidth: "100px",
    maxWidth: "200px",
    height: "164px",
  };

  return (
    <div
      className="boxTopRounded topBMargin boxSmRounded text-center"
      style={{
        transform: `scale(${scale})`,
        transition: "transform 0.5s cubic-bezier(0.25, 0.1, 0.25, 1)",
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <img src={image} alt="btn logo" style={mystyle} />
    </div>
  );
};

export default BoxZoom;
