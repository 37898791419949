import React from "react";
import httpClient from "../services/httpClient";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ConfirmPromotionImage from "../images/pro_new_mem.jpg";

const MySwal = withReactContent(Swal);

const backgroundRounded = {
  backgroundImage:
    "linear-gradient(to right, #051937, #004d7a, #008793, #00bf72, #a8eb12)",
  borderRadius: "10px 10px 10px 10px",
};

const ConfirmPromotion = () => {
  const navigate = useNavigate();
  const [promotion, setPromotion] = React.useState(false);
  const [getPromotion, setGetPromotion] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const alertPromotion = async () => {
    if (promotion) {
      setPromotion(false);
    } else {
      setPromotion(true);
    }
  };

  const getData = async () => {
    setLoading(true);
    try {
      const res1 = await httpClient.get("/authen/getPromotion");
      console.log(res1.data);
      setGetPromotion(res1.data.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = () => {
    if (promotion) {
      MySwal.fire({
        title: "โปรสมัครใหม่",
        html:
          "รับโบนัสฝากขั้นต่ำ 100 บาท โบนัสสูงสุด 500 บาท ทำเทิร์น 1 เท่า" +
          'ถอนสูงสุด 3,000 บาท <span style="color: red">เล่นได้เฉพาะสล็อตกับยิงปลาเท่านั้น</span>' +
          " ถ้าหากเล่นผิดกฎคืนเงินเท่ายอดฝากทุกกรณี กรณีที่มีเจตนาซ่อนฟรีสปินยอดทั้งหมดจะเป็นโมฆะ",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน!",
        cancelButtonText: "ยกเลิก",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await sendFinal();
        }
      });
    } else {
      MySwal.fire({
        title: "ไม่รับโบนัส?",
        text: "ไม่รับโบนัส ฝากไม่มีขั้นต่ำ ถอนขั้นต่ำ 100 บาท เล่นได้ทุกเกมส์ไม่จำกัด",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน!",
        cancelButtonText: "ยกเลิก",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await sendFinal();
        }
      });
    }
  };

  React.useEffect(() => {
    if (localStorage.getItem("registerId") && localStorage.getItem("pin")) {
      getData();
    } else {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendFinal = async () => {
    try {
      setLoading(true);
      let aPromotion = null;
      if (promotion) {
        aPromotion = getPromotion.promotion.id;
      } else {
        aPromotion = 1;
      }
      const res1 = await httpClient.post("/authen/register", {
        registerId: localStorage.getItem("registerId"),
        pinCode: localStorage.getItem("pin"),
        pinCode2: localStorage.getItem("pin"),
        promotionId: aPromotion,
      });

      const resp = await httpClient.post("/authen/login", {
        phoneNumber: res1.data.data.phoneNumber,
        pinCode: res1.data.data.pinCode,
      });
      localStorage.setItem("accessToken", resp.data.data.accessToken);
      localStorage.removeItem("registerId");
      localStorage.removeItem("pin");
      navigate("/auth/dashboard");
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="boxTopRounded topBMargin boxSmRounded mb20">
            <span className="imgBonus">
              <img src={ConfirmPromotionImage} alt="banner" />
            </span>
            <div style={backgroundRounded} className="p-2 round">
              <div className="form-check form-switch ">
                <input
                  onClick={() => alertPromotion()}
                  className={`form-check-input checkbox-changer ${
                    promotion ? "active" : ""
                  }`}
                  type="checkbox"
                  id="switch"
                />
                <label className="form-check-label" htmlFor="switch">
                  ต้องการรับโบนัสหรือไม่?
                </label>
              </div>
            </div>
            <div className="d-grid gap-2 mt-2">
              {loading ? (
                <Button className="btn-block mt-3 btnPrimary" disabled>
                  กำลังโหลด
                </Button>
              ) : (
                <Button
                  className="btn-block mt-3 btnPrimary"
                  onClick={onSubmit}
                >
                  ยืนยัน
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPromotion;
