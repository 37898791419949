import React from "react";
import { Button, Modal } from "react-bootstrap";
import httpClient from "../services/httpClient";
import { toast } from "react-hot-toast";

const UnSuccessReport = ({ reports, refreshData }) => {
  const [show, setShow] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onRefreshData = async () => {
    refreshData();
  };

  const storeFromUnSuccess = async (reportId) => {
    try {
      await httpClient.post("/main/depositFromUnsuccessReport/" + reportId);
      setShow(false);
      toast.success("เติมเงินเรียบร้อยแล้ว");
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      await onRefreshData();
      setLoading(false);
    }
  };

  const preferStore = (reportId) => {
    setLoading(true);
    if (window.confirm("ยืนยันการเติมเงิน?")) {
      storeFromUnSuccess(reportId);
    } else {
      setLoading(false);
      return;
    }
  };
  return (
    <>
      <div className="col-12">
        <div className="input_box mb-3">
          <div
            onClick={handleShow}
            className="btnPrimary btnBlue twinkling-button"
            style={{
              background: "linear-gradient(rgb(255 153 163), rgb(202 0 19))",
            }}
          >
            <span>ยอดฝากค้าง {reports.length} รายการ</span>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        dialogClassName="modal-dialog modal-dialog-centered"
      >
        <Modal.Header closeButton>
          <Modal.Title>รายการฝากค้าง</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <table className="table table-bordered table-dark">
            <thead>
              <tr>
                <th>วันที่/เวลา</th>
                <th>จำนวนเงิน</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {reports.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.date}</td>
                    <td>
                      <strong className="text-success">{item.amount}</strong>
                    </td>
                    <td>
                      {loading ? (
                        <button className="btn btn-sm btn-success" disabled>
                          กำลังโหลด...
                        </button>
                      ) : (
                        <button
                          className="btn btn-sm btn-success"
                          onClick={() => {
                            preferStore(item.id);
                          }}
                        >
                          เติม
                        </button>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose()}>
            ปิด
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UnSuccessReport;
