import React from "react";
import IconBack from "../images/icon-back.svg";
import { Button, Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ContactComponent from "../components/ContactComponent";
import { toast } from "react-hot-toast";

const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, "C", 0, "D"];

const ConfirmPinPage = () => {
  const navigate = useNavigate();
  const [inputValues, setInputValues] = React.useState(["", "", "", ""]);
  const [valuePosition, setValuePosition] = React.useState(0);

  const handleClick = (number) => {
    if (valuePosition < 4) {
      const newInputValues = [...inputValues];
      newInputValues[valuePosition] = number;
      setInputValues(newInputValues);
      setValuePosition(valuePosition + 1);
    }
  };

  const handleDelete = () => {
    if (valuePosition > 0) {
      const newInputValues = [...inputValues];
      newInputValues[valuePosition - 1] = "";
      setInputValues(newInputValues);
      setValuePosition(valuePosition - 1);
    }
  };

  const renderRows = () => {
    const rows = [];
    for (let i = 0; i < numbers.length; i += 3) {
      const rowItems = numbers.slice(i, i + 3);
      const row = (
        <Row key={`row-${i}`} className="mt-3">
          {rowItems.map((number) => (
            <Col key={`col-${number}`}>
              <div className="d-grid gap-2">
                {number === "C" ? (
                  <Button
                    variant="warning"
                    onClick={() => {
                      setInputValues(["", "", "", ""]);
                      setValuePosition(0);
                    }}
                  >
                    Clear
                  </Button>
                ) : number === "D" ? (
                  <Button variant="warning" onClick={() => handleDelete()}>
                    Delete
                  </Button>
                ) : (
                  <Button variant="warning" onClick={() => handleClick(number)}>
                    {number}
                  </Button>
                )}
              </div>
            </Col>
          ))}
        </Row>
      );
      rows.push(row);
    }
    return rows;
  };

  const onSubmit = async () => {
    const firstPin = localStorage.getItem("pin");
    if (firstPin !== inputValues.join("")) {
      toast.error("รหัส PIN ไม่ตรงกัน");
      setInputValues(["", "", "", ""]);
      setValuePosition(0);
      return;
    } else {
      navigate("/confirm-promotion");
    }
  };
  React.useEffect(() => {
    if (valuePosition === 4) {
      onSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValues]);
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <Link to="/" className="backlink">
            <img src={IconBack} alt="icons" />
          </Link>
          <div className="boxTopRounded mt-4">
            <h2 className="titleH2">ยืนยันรหัส pin</h2>
            <div className="formBox text-center">
              {inputValues.map((inputValue, index) => (
                <input
                  type="password"
                  style={
                    valuePosition > index ? { backgroundColor: "red" } : {}
                  }
                  className="form-control inputBox inputInline"
                  key={index}
                />
              ))}
            </div>
            <div className="mb-4">{renderRows()}</div>
          </div>
        </div>
      </div>
      <div className="text-center">
        <ContactComponent text="ติดต่อแอดมิน" />
      </div>
    </div>
  );
};

export default ConfirmPinPage;
