import React from "react";
import HeaderMainPage from "../../components/header/mainPage";
import toast from "react-hot-toast";
import httpClient from "../../services/httpClient";
import { useForm } from "react-hook-form";
import { copyToClipboard } from "../../services/helpers";
import { Button, Modal } from "react-bootstrap";
import AlertNotify from "../../components/AlertNotify";

const acursor = { cursor: "pointer" };

const ProfilePage = () => {
  const [loading, setLoading] = React.useState(false);
  const [gameUsername, setGameUsername] = React.useState("");
  const [gamePassword, setGamePassword] = React.useState("");
  const [banks, setBanks] = React.useState([]);
  const [fullname, setFullname] = React.useState("");
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { register, handleSubmit } = useForm();

  const getData = async () => {
    setLoading(true);
    try {
      const res1 = await httpClient.get("/main/getProfile");
      setGameUsername(res1.data.data.customer.game_username);
      setGamePassword(res1.data.data.customer.password);
      setBanks(res1.data.data.banks);
      setFullname(
        res1.data.data.customer.f_name + " " + res1.data.data.customer.l_name
      );
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const formatNumber = (num) => {
    const regex = /^(\d{3})(\d{1})(\d{5})(\d{1})$/;
    return num.replace(regex, "$1-$2-$3-$4");
  };

  const handleCopy = (text) => {
    copyToClipboard(text);
    toast.success(`คัดลอก ${text} สำเร็จ`);
  };

  const formatToLowerCase = (text) => {
    return text.toLowerCase();
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const res = await httpClient.post("/main/changePass", {
        password: data.password,
        confirm_password: data.confirm_password,
      });
      await getData();
      toast.success(res.data.message);
      handleClose();
    } catch (error) {
      if (error.response.data.errors.password) {
        toast.error(error.response.data.errors.password[0]);
      } else {
        toast.error(error.response.data.errors.confirm_password[0]);
      }
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <div className="container">
      <AlertNotify />
      <div className="row justify-content-center">
        <div className="col-md-6">
          <HeaderMainPage title="ข้อมูลผู้ใช้งาน" url="/auth/dashboard" />
          <div className="boxTopRounded miniToplogo">
            <img
              src={require("../../images/logo.svg").default}
              alt="logotop"
              className="logoTopAb"
            />
            <label className="topLabel">รหัสผู้ใช้งาน</label>

            <div className="formBox">
              <input
                type="text"
                className="form-control inputBox inputRight"
                placeholder={gameUsername}
                disabled
              />

              <a
                href={() => false}
                style={acursor}
                className="miniBtn btnRight"
                onClick={() => handleCopy(gameUsername)}
              >
                คัดลอก
              </a>
            </div>
            <div className="rowLabel">
              <label className="topLabel">รหัสผ่านเข้าใช้งาน</label>
              <a href={() => false} style={acursor} onClick={handleShow}>
                เปลี่ยนรหัสผ่าน
              </a>
            </div>
            <div className="formBox">
              <input
                type="text"
                className="form-control inputBox inputRight"
                placeholder={gamePassword}
                disabled
              />
              <a
                href={() => false}
                style={acursor}
                onClick={() => handleCopy(gamePassword)}
                className="miniBtn btnRight"
              >
                คัดลอก
              </a>
            </div>
          </div>
          {banks.map((item, index) => (
            <div className="boxTopRounded topBMargin boxSmRounded" key={index}>
              <h3 className="titleWithLine">ข้อมูลบัญชีธนาคาร</h3>
              <div className="rowBank">
                <div className="iconBank">
                  <i
                    className={`bank bank-${formatToLowerCase(
                      item.bank_list.bank_abbrev_en
                    )} huge`}
                  ></i>
                </div>
                <div className="colBankname ms-3">
                  {item.bank_list.bank_name_th}
                </div>
              </div>
              <div className="rowName">
                <h4>{fullname}</h4>
                <span>เลขที่บัญชี {formatNumber(item.number)}</span>
              </div>
              <div className="rowVerify">
                <img
                  src={require("../../images/icon-verify.svg").default}
                  alt="verify"
                />{" "}
                ยืนยันตัวตนแล้ว
              </div>
            </div>
          ))}
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="modal-dialog modal-dialog-centered"
      >
        <Modal.Body>
          <div>
            <h3 className="titleWithLine inModal">เปลี่ยนรหัสผ่าน</h3>
            <a
              href={() => false}
              style={acursor}
              onClick={handleClose}
              className="iconClose"
            >
              {/* <img src="images/icon-close.svg" /> */}
              <img
                src={require("../../images/icon-close.svg").default}
                alt="iconclose"
              />
            </a>
            <div className="bgInfo">
              <img
                src={require("../../images/icon-info.svg").default}
                alt="iconInfo"
              />
              ข้อมูลบัญชีธนาคารต้องตรงกับชื่อที่สมัครเท่านั้น
              และเลขบัญชีต้องตรงกับบัญชีจริงเท่านั้นจึงจะทำรายการ ฝาก-ถอนได้
              ถ้าหากแจ้งบัญชีชื่อมาไม่ตรงกับชื่อ-นามสกุล ที่ลงทะเบียน
              ขอสงวนสิทธิ์ในการถอนทุกกรณี
            </div>
            <label className="topLabel">รหัสผ่านเดิม</label>
            <div className="formBox">
              <input
                type="text"
                className="form-control inputBox"
                placeholder={gamePassword}
                disabled
              />
            </div>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="formBox">
                <input
                  type="password"
                  className="form-control inputBox"
                  placeholder="ใส่รหัสผ่านใหม่"
                  {...register("password")}
                />
              </div>
              <div className="formBox">
                <input
                  type="password"
                  className="form-control inputBox"
                  placeholder="ใส่รหัสผ่านใหม่อีกครั้ง"
                  {...register("confirm_password")}
                />
              </div>
              {loading ? (
                <Button className="btnPrimary btnFullWidth" disabled size="sm">
                  กำลังโหลด...
                </Button>
              ) : (
                <Button
                  type="submit"
                  className="btnPrimary btnFullWidth"
                  size="sm"
                >
                  ยืนยันเปลี่ยนรหัสผ่าน
                </Button>
              )}
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ProfilePage;
