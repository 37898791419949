import React from "react";
import { Link, useNavigate } from "react-router-dom";
import httpClient from "../../services/httpClient";
import { toast } from "react-hot-toast";
import { BiRefresh } from "react-icons/bi";
import UnSuccessReport from "../../components/UnSuccessReport";
import AlertNotify from "../../components/AlertNotify";

const DashboardPage = () => {
  const navigate = useNavigate();
  const [balance, setBalance] = React.useState(0);
  const [playUrl, setPlayUrl] = React.useState("");
  const [promotion, setPromotion] = React.useState("กำลังโหลด...");
  const [customerName, setCustomerName] = React.useState("กำลังโหลด...");
  const [username, setUsername] = React.useState("กำลังโหลด...");
  const [reports, setReports] = React.useState([]);
  const [lineLink, setLineLink] = React.useState("");

  const getData = async () => {
    try {
      const res1 = await httpClient.get("/game/getCredit");
      const res2 = await httpClient.post("/game/playGame");
      const res3 = await httpClient.get("/main/getCurrentPromotion");
      const res4 = await httpClient.get("/main/getProfile");
      const res5 = await httpClient.get("/main/getUnsuccessReport");
      const res6 = await httpClient.get("/authen/getNameAndContact");
      setLineLink(res6.data.lineLink);
      setPromotion(res3.data.data);
      setBalance(res1.data.data.balance);
      setPlayUrl(res2.data.data.url);
      setReports(res5.data.data);
      setCustomerName(
        res4.data.data.customer.f_name + " " + res4.data.data.customer.l_name
      );
      setUsername(res4.data.data.customer.game_username);
    } catch (error) {}
  };

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    toast.success("ออกจากระบบสำเร็จ");
    navigate("/");
  };

  const handleRefresh = () => {
    setBalance("กำลังโหลด...");
    getData();
  };

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <div className="container">
      <AlertNotify />
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="colHi">
            <h1>สวัสดี,</h1>
          </div>
          <div className="bgUserTop">
            <h4>{customerName}</h4>
            <span>รหัสผู้ใช้งาน: {username}</span>
            <a
              href="/#"
              onClick={(e) => {
                e.preventDefault();
                handleLogout();
              }}
              className="miniBtn miniDark"
            >
              ออกจากระบบ
            </a>
            <img
              src={require("../../images/logo.svg").default}
              alt="logo"
              className="logoTopAb topDashLogo"
            />
          </div>
          <div className="boxTopRounded mt-4 normalPad bgOverlay mbDash">
            <div className="rowCredit">
              <div className="colCoin">
                <img
                  src={require("../../images/icon-credit.png")}
                  alt="icon-credit"
                  className="iconCredit"
                />
              </div>
              <div className="colCredit">
                <span>ยอดเงินคงเหลือ</span>
                <h2>
                  ฿{balance}{" "}
                  <BiRefresh
                    onClick={() => handleRefresh()}
                    size={25}
                    color={"#fff"}
                    style={{
                      backgroundColor: "rgb(239 50 76)",
                      borderRadius: 10,
                      cursor: "pointer",
                      marginTop: 20,
                      marginRight: 70,
                    }}
                    className="float-end"
                  />
                </h2>
              </div>
            </div>
            <div>
              {reports.length > 0 && (
                <UnSuccessReport refreshData={getData} reports={reports} />
              )}
            </div>
            <div className="rowBtn">
              <div className="btnDeposit pr-2">
                <Link to="/auth/choose" className="btnPrimary">
                  ฝากเงิน
                </Link>
              </div>
              <div className="btnWithdraw pl-2">
                <Link to="/auth/withdraw" className="btnPrimary btnBlue">
                  ถอนเงิน
                </Link>
              </div>
            </div>
            <hr className="hrline" />
            <div className="rowBonus">
              <div className="colBonus">
                <div className="rowFree">
                  <div className="rowFreeno">
                    <span>สถานะโปรโมชั่น</span>
                    <h5>{promotion}</h5>
                  </div>
                </div>
              </div>
            </div>
            <a
              href={playUrl}
              className="btnPlay"
              target="_blank"
              rel="noreferrer"
            >
              <img src={require("../../images/btn-play.png")} alt="btn-play" />
            </a>
          </div>
          <div className="boxMenu">
            <div className="row">
              <div className="col-4">
                <div className="bgMenu">
                  <Link to="/auth/profile">
                    <img
                      src={require("../../images/m-1.png")}
                      alt="menu"
                      className="iconMenu"
                    />
                    ข้อมูลผู้ใช้งาน
                  </Link>
                </div>
              </div>
              <div className="col-4">
                <div className="bgMenu">
                  <Link to="/auth/bonus">
                    <img
                      src={require("../../images/m-2.png")}
                      alt="bonus"
                      className="iconMenu"
                    />
                    ตั้งค่ารับโบนัส
                  </Link>
                </div>
              </div>
              <div className="col-4">
                <div className="bgMenu">
                  <Link to="/auth/histories">
                    <img
                      src={require("../../images/m-3.png")}
                      alt="depositwithdraw"
                      className="iconMenu"
                    />
                    ประวัติฝากถอน
                  </Link>
                </div>
              </div>
              <div className="col-4">
                <div className="bgMenu">
                  <Link to="/auth/conditions">
                    <img
                      src={require("../../images/m-4.png")}
                      alt="withdraw"
                      className="iconMenu"
                    />
                    เงื่อนไขการถอน
                  </Link>
                </div>
              </div>
              <div className="col-4">
                <div className="bgMenu">
                  <Link to="/auth/dashboard">
                    <img
                      src={require("../../images/m-5.png")}
                      alt="game"
                      className="iconMenu"
                    />
                    เกมในเครือข่าย
                  </Link>
                </div>
              </div>
              <div className="col-4">
                <div className="bgMenu">
                  <a href={lineLink}>
                    <img
                      src={require("../../images/m-6.png")}
                      alt="contect"
                      className="iconMenu"
                    />
                    ติดต่อเรา
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
