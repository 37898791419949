import React from "react";
import { Link } from "react-router-dom";

import HeaderMainPage from "../../../components/header/mainPage";

import pcimg from "../../../images/Bank.png";
import timg from "../../../images/true.png";

import BoxZoom from "../../../components/BoxZoom";
import AlertNotify from "../../../components/AlertNotify";

const Choose = () => {
  return (
    <>
      <div className="container">
        <AlertNotify />
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-8">
            <div className="col-12">
              <HeaderMainPage title="ฝากเงิน" url="/auth/dashboard" />
            </div>
            <div className="col-12">
              <h3 className="text-center mt-4">กรุณาเลือกระบบการฝากเงิน</h3>
            </div>
            <div className="row">
              <div className="col-12 col-sm-6">
                <Link to="/auth/deposit/banks">
                  <BoxZoom image={pcimg} />
                </Link>
              </div>
              <div className="col-12 col-sm-6">
                <Link to="/auth/deposit/truemoney">
                  <BoxZoom image={timg} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Choose;
